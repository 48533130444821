/* font face declarations */

/* Variable setup for OBIS corporate identity */
:root {
  --title-font: 'Proxima Nova Alt Bold', Georgia, sans-serif;
  --regular-font: 'Proxima Nova Regular', Georgia, sans-serif;
  --font-color: #363636;

  --light-background: #f3f3f3;
  --white: #ffffff;

  --margin-top: 1.5rem;
  --margin-bottom: 1.5rem;

  --link-color: rgb(204, 51, 0);

  --obis-green: #28a745;
}

/* Variables needing to be declared as SCSS vars due to usage in SCSS functions */
$step-background: #05dbf2;
$obis-green: #28a745;

/* Bulma vars to override */
$family-primary: var(--regular-font);
$link: rgb(204, 51, 0);

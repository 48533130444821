$section-padding: 0.5rem;

@import '../../node_modules/bulma/bulma';
@import '../../node_modules/bulma-checkradio/src/sass/index';
@import '../../node_modules/flatpickr/dist/flatpickr.css';
@import '../../dwca-lib/src/all';
@import 'stylesheets/variables';
@import 'stylesheets/theme';

@import 'components/layout/Divider';
@import 'components/layout/Navbar';
@import 'components/pages/HelpPage';
@import 'components/pages/InputDataPage';
@import 'components/pages/Occurrence/DarwinCoreFields/DarwinCoreFields';
@import 'components/pages/Occurrence/Dataset/Dataset';
@import 'components/pages/Occurrence/FinalSummary/FinalSummary';
@import 'components/pages/Occurrence/LocationData/LocationData';
@import 'components/pages/Occurrence/LocationData/LocationPicker';
@import 'components/pages/Occurrence/LocationData/LocationPickerModal';
@import 'components/pages/Occurrence/MeasurementOrFact/MeasurementOrFact';
@import 'components/pages/Occurrence/ObservationData/ObservationData';
@import 'components/pages/Occurrence/OccurrenceData/OccurrenceData';
@import 'components/pages/Occurrence/OccurrenceData/ScientificNameInput';
@import 'components/pages/Occurrence/OccurrenceNotSupported';

html {
  background-color: transparent;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--regular-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

header {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  color: inherit;
  margin-bottom: var(--margin-bottom);
}

a {
  color: var(--link-color);
}

/* Override Bulma style */
.button.is-info:hover,
.button.is-info.is-hovered {
  background-color: darken($blue, 5%);
}

.location-data {
  margin-bottom: 1.25rem;

  .event-begin-date {
    .datetimepicker-clear-button {
      display: none;
    }
  }

  .verbatim-data {
    background-color: $background;
    padding: 1.25em 1.5em;
    border-radius: $radius;
  }

  .location-picker-notice {
    background-color: var(--obis-green);
    cursor: pointer;
    color: #fff;
    margin-bottom: var(--margin-bottom);
  }

  .no-margin {
    margin-bottom: 0;
  }
}

.modal-card-head {
  margin-bottom: 0;
}

.navbar {
  &.is-info {
    background-color: transparent;
  }

  .navbar-menu .navbar-start a {
    font-family: var(--title-font);

    &.navbar-item {
      color: var(--font-color);
    }

    &.navbar-item.is-active,
    &.navbar-item:hover {
      color: white;
      background-color: var(--obis-green);
    }
  }

  .navbar-brand {
    p {
      padding-right: 10px;
      font-size: 26px;
      font-weight: bold;
    }

    a.navbar-item.logo {
      background-color: white;
      color: var(--font-color);

      &:hover,
      &:visited {
        background-color: white;
        color: var(--font-color);
      }
    }
  }

  .login-nav-item.navbar-end {
    .navbar-item {
      color: var(--font-color);
      &.auth-button:hover {
        background-color: var(--obis-green);
        color: white;
      }
    }
  }

  .navbar-burger {
    span {
      color: black;
    }
  }
}

.previous-occurrences {
  margin-top: var(--margin-top);

  .copy-previous-entry {
    background-color: $step-background;
  }
}

a.button.new {
  background-color: var(--obis-green);
  margin-bottom: var(--margin-bottom);

  &:hover {
    background-color: lighten($obis-green, 10%);
  }
}

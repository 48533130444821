$section-padding: 0.5rem;
@import 'variables';
@import '../../node_modules/bulma/bulma';
@import '../../node_modules/bulma-checkradio/src/sass/index';

@import 'components/form/Dropdown';
@import 'components/form/InputRadio';
@import 'components/StepHeaders/CopyPreviousData';
@import 'components/StepHeaders/StepFooter';
@import 'components/StepHeaders/StepHeader';

.step-footer {
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;

  .dataset-continue-button {
    display: flex;
    margin-top: 1rem;

    button {
      margin-left: auto;
    }
  }

  .copy-previous-data {
    color: var(--link-color);
  }
}

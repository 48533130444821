.occurrence-not-supported {
  .main-message {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .exception-message {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  pre {
    max-height: 30rem;
  }
}

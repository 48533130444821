.measurement-or-fact {
  margin-bottom: 1.25rem;

  .supplied {
    background-color: $background;
    align-self: flex-start;
    padding-bottom: 3rem;
  }

  .dropdown {
    .selected-value {
      min-width: 8rem;
    }

    .button {
      text-align: left;
    }
  }

  td {
    vertical-align: middle;
  }
}

.occurrence-data {
  margin-bottom: 1.25rem;

  .dropdown {
    display: block;

    .dropdown-content {
      max-height: 15em;
      overflow: auto;
    }

    .dropdown-menu {
      width: 100%;
    }
  }

  .field {
    .label {
      small {
        font-size: 0.75em;
        font-style: italic;
      }
    }
  }

  .input-radio-group {
    margin-right: 180px;
  }
}

.worms-info {
  font-size: $size-7;
}

.scientific-name {
  position: relative;
  z-index: 1;
}

.scientific-name.is-danger {
  background-color: #ffeb3b;
  border-color: black;
}

.scientific-name.is-danger ~ .invalid-worms::after {
  content: 'taxon not matched with WoRMS, scientificNameID will be omitted';
  position: absolute;
  z-index: 1000;
  padding: 0.2rem;
  margin-left: 0.4rem;
  font-size: $size-7;
  word-break: keep-all;
  white-space: nowrap;
  border-bottom: 3px solid orange;
  background-color: white;
}

.step-header {
  cursor: pointer;

  @for $i from 0 through 100 {
    &.background-color-#{$i} {
      background-color: darken($step-background, $i * 1%);
    }
  }

  .details {
    text-align: right;

    .check-circle {
      color: lighten($info, 20%);
      margin-right: 6px;
    }

    .step-title:hover {
      text-decoration: underline;
    }
  }

  h2 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: $size-5;
  }

  .selected-data {
    font-size: $size-6;
  }
  .datadescription {
    font-weight: bold;
    font-size: $size-5;
    text-transform: uppercase;
  }
}
